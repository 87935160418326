@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&family=Montserrat:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif; 
  font-weight: 500; 
  color: #002657;
}

.hero-section {
  text-align: center;
  padding: 40px 20px;
  padding-top: 180px;
  padding-bottom: 180px;
  position: relative;
  overflow: hidden;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.hero-content {
  color: white;
  position: relative;
  z-index: 1;
}

.hero-section {
  position: relative;
}

.title {
  font-size: 3rem;
  margin: 0px 0;
  font-family: 'Roboto', sans-serif;
}

.heading {
  font-size: 2.2rem;
  margin: 0px 0;
  font-family: 'Roboto', sans-serif;
}

.subheading {
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
}

.cta-button {
  background-color: #398efb;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.section hr {
  border: 0;
  height: 1px;
  background-color: #e0e0e0;
  width: 70%;
  margin: 40px auto;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  padding-top: 1rem;
}

.section {
  width: 100%;
  padding: 1rem 0;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}

.section .content {
  width: 70%;
  margin: 0 auto;
  text-align: left;
  padding: 0 20px;
}

.section .content .heading {
  font-family: 'Roboto', sans-serif;
}

.section .content p {
  line-height: 1.6;
}

.section .content ul,
.section .content ol {
  line-height: 2;
}

.section .content li {
  margin-bottom: 10px;
}

@media screen and (min-width: 550px) {
  .countdown .content {
    gap: 30px;
  }
  .countdown .value {
    width: 100px;
    height: 100px;
  }
  .countdown .number {
    font-size: 40px;
  }
  .countdown .box {
    height: 150px;
    max-width: 150px;
  }
}

@media screen and (min-width: 790px) {
  .countdown .value {
    width: 120px;
    height: 120px;
  }
  .countdown .number {
    font-size: 50px;
  }
  .countdown .box {
    height: 200px;
    max-width: 200px;
  }
}

.scroll-arrow {
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: block;
  margin: 0px auto 0; 
  transition: transform 0.2s ease;
}

.scroll-arrow:hover {
  transform: translateY(5px);
}

.countdown {
  margin-bottom: 0; 
  color: #002657;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 30px; 
    margin: 10px 0;
    padding: 0 20px;
  }
  
  .button-group button {
    padding: 15px 40px;
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    background-color: transparent;
    color: #002657;
    border: 2px solid #398efb;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 200px;
  }
  
  .button-group button:hover {
    background-color: #398efb;
    color: white;
  }
  
  .button-group button.active {
    background-color: #398efb;
    color: white;
  }
  
  .content {
    width: 50%;
    margin: 0 auto;
    text-align: left;
    padding: 0px 20px;
  }
  
  .content h2 {
    font-size: 2.2rem;
    margin: 0 0 20px 0;
    font-family: 'Roboto', sans-serif;
  }
  
  .content p {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .content > div {
    animation: fadeIn 0.3s ease-in;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .content > div {
    animation: fadeIn 0.3s ease-in;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.ecoprize-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}