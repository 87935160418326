@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&family=Montserrat:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif; 
  font-weight: 500; 
  color: #002657;
  margin: 0; 
  padding: 0;
}

.hero-section {
  text-align: center;
  position: relative;
  padding-top: 180px;
  padding-bottom: 180px;
  overflow: hidden;
}

.hero-video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -2;
}

.hero-video {
  width: 100%;
  height: 100%;
  border: none;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1; 
}

.hero-content {
  color: white;
  position: relative;
  z-index: 1;
}

.title {
  font-size: 3rem;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.subheading {
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
}

.subcountdown {
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
}

.scroll-arrow {
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: block;
  margin: 0px auto 0;
  transition: transform 0.2s ease;
}

.scroll-arrow:hover {
  transform: translateY(5px);
}

.cta-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: #398efb;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  border-radius: 5px;
  box-sizing: border-box; 
  width: auto;
  max-width: fit-content; 
  white-space: nowrap; 
}

.cta-button:hover {
  background-color: #306dc7;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  padding-top: 3rem;
}

.section {
  width: 100%;
  padding: 1rem 0;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section hr {
  border: 0;
  height: 1px;
  background-color: #e0e0e0;
  width: 70%;
  margin: 40px auto;
}

.section .content {
  width: 70%;
  margin: 0 auto;
  text-align: left;
  padding: 0 20px;
}

.section .content .heading {
  font-family: 'Roboto', sans-serif;
  font-size: 2.2rem;
  margin: 0;
}

.section .content p {
  line-height: 1.6;
}

.section .content ul,
.section .content ol {
  line-height: 2;
}

.section .content li {
  margin-bottom: 10px;
}


@media screen and (min-width: 550px) {
  .countdown .content {
    gap: 30px;
  }
  .countdown .value {
    width: 100px;
    height: 100px;
  }
  .countdown .number {
    font-size: 40px;
  }
  .countdown .box {
    height: 150px;
    max-width: 150px;
  }
}

@media screen and (min-width: 790px) {
  .countdown .value {
    width: 120px;
    height: 120px;
  }
  .countdown .number {
    font-size: 50px;
  }
  .countdown .box {
    height: 200px;
    max-width: 200px;
  }
}

.countdown {
  margin-bottom: 0; 
  color: #002657;
}