@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&family=Montserrat:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif; 
  font-weight: 500; 
  color: #002657;
}

.hero-section {
  text-align: center;
  padding: 40px 20px;
  padding-top: 180px;
  padding-bottom: 180px;
  position: relative;
  overflow: hidden;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.hero-content {
  color: white;
  position: relative;
  z-index: 1;
}

.hero-section {
  position: relative;
}

.title {
  font-size: 3rem;
  margin: 0px 0;
  font-family: 'Roboto', sans-serif;
}

.heading {
  font-size: 2.2rem;
  margin: 0px 0;
  font-family: 'Roboto', sans-serif;
}

.subheading {
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
}

.cta-button {
  background-color: #398efb;
  color: white;
  border: none;
  padding: 15px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  border-radius: 5px;
  position: relative;
  transition: transform 0.2s, background-color 0.2s;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.cta-button:hover {
  transform: translateY(0) scale(1.05);
  background-color: #2a7fe9;
}

.cta-button:active {
  transform: translateY(0) scale(0.95);
}


.section hr {
  border: 0;
  height: 1px;
  background-color: #e0e0e0;
  width: 50%;
  margin: 40px auto;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  padding-top: 3rem;
}

.section {
  width: 100%;
  padding: 1rem 0;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}

.section .content {
  width: 50%;
  margin: 0 auto;
  text-align: left;
  padding: 0 20px; 
}

.section .content .heading {
  font-family: 'Roboto', sans-serif;
}

.section .content p {
  line-height: 1.6;
}

.section .content ul,
.section .content ol {
  line-height: 2;
}

.section .content li {
  margin-bottom: 10px;
}

@media screen and (min-width: 550px) {
  .countdown .content {
    gap: 30px;
  }
  .countdown .value {
    width: 100px;
    height: 100px;
  }
  .countdown .number {
    font-size: 40px;
  }
  .countdown .box {
    height: 150px;
    max-width: 150px;
  }
}

@media screen and (min-width: 790px) {
  .countdown .value {
    width: 120px;
    height: 120px;
  }
  .countdown .number {
    font-size: 50px;
  }
  .countdown .box {
    height: 200px;
    max-width: 200px;
  }
}

.scroll-arrow {
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: block;
  margin: 0px auto 0; 
  transition: transform 0.2s ease;
}

.scroll-arrow:hover {
  transform: translateY(5px);
}

.countdown {
  margin-bottom: 0; 
  color: #002657;
}

.faq-item {
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.faq-button {
  width: 100%;
  padding: 1rem;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  transition: all 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-button:hover {
  background-color: #f5f5f5;
}

.faq-button.active {
  background-color: #398efb;
  color: white;
}

.faq-answer {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 1.5rem;
  background-color: #fff;
}

.faq-answer.show {
  max-height: 500px;
  opacity: 1;
}


.rubric-category.extra-margin img {
  margin-left: 20px; /* Increased from 15px for more whitespace */
  margin-right: 20px
}

.rubric-category {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.rubric-category img {
  max-height: 60px; /* Keeps tall images scaled down */
  width: auto;
  border-radius: 5px; /* Optional: Rounded corners */
  margin-right: 15px;
}

.rubric-text {
  display: flex;
  flex-direction: column;
}

.rubric-text h3 {
  margin: 0;
  font-size: 1.2rem;
}

.rubric-text p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}

